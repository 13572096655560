import _extends from "/home/buildagent/buildAgent/work/35e0d48fca3726c0/node_modules/next/node_modules/@babel/runtime/helpers/esm/extends";
import _defineProperty from "/home/buildagent/buildAgent/work/35e0d48fca3726c0/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _slicedToArray from "/home/buildagent/buildAgent/work/35e0d48fca3726c0/node_modules/next/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import React from "react";
var __jsx = React.createElement;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { DEFAULT_THEME } from '@anm/constants/videoLandings';
import * as sentry from '@anm/helpers/sentry/client';
import NextApp from '@anm/pages/App';
import { landingFontsMap } from '@anm/styles/fonts';
import { lightTheme } from '@anm/styles/theme';
import { ThemeProvider } from 'styled-components';
import config from '../config';
import { FontsProvider } from '@anm/contexts/FontsContext';
import GlobalStyle from '../styles/GlobalStyles';
import useDynamicPage from '@anm/hooks/useDynamicPage';
sentry.init(config.sentry);

var App = function App(props) {
  var _page$parameters;

  var _useDynamicPage = useDynamicPage({
    pageFromProps: props.pageProps.page
  }),
      _useDynamicPage2 = _slicedToArray(_useDynamicPage, 1),
      page = _useDynamicPage2[0];

  var currentTheme = (page === null || page === void 0 ? void 0 : (_page$parameters = page.parameters) === null || _page$parameters === void 0 ? void 0 : _page$parameters.theme) || DEFAULT_THEME;
  return __jsx(ThemeProvider, {
    theme: lightTheme
  }, __jsx(FontsProvider, {
    value: {
      family: landingFontsMap[currentTheme].family
    }
  }, __jsx(NextApp, _extends({}, props, {
    pageProps: _objectSpread(_objectSpread({}, props.pageProps), {}, {
      page: page
    })
  }), __jsx(GlobalStyle, null))));
};

export default App;