import { FontFamily } from '@anm/styles/fonts';
import React from 'react';

type FontsContextProps = {
  family?: FontFamily;
};

export const FontsContext = React.createContext<FontsContextProps>({});

export const FontsProvider = FontsContext.Provider;
