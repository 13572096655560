import { FormatNames, LandingTheme } from '@anm/api/modules/hosting';

export const OUTDATED_TEMPLATE = 'basic';
export const HORIZONTAL_TEMPLATE = 't1';
export const VERTICAL_TEMPLATE = 't2';
export const DEFAULT_THEME = 'pure';
export const DEFAULT_HAS_FOOTER = true;
export const DEFAULT_VIDEO_RATIO = 16 / 9;
export const DATA_WRAPPER_WIDTH = 720;
export const DESKTOP_BREAKPOINT = 992;
export const DEFAULT_THUMBNAIL_WIDTH = 320;

export const ALL_THEMES: LandingTheme[] = [
  'pure',
  'daisy',
  'dawn',
  'deep',
  'dew',
  'dusk',
  'lily',
  'mist',
  'night',
  'pale',
  'peony',
  'sand'
];

export const DEFAULT_MOBILE_WIDTH = 375;
export const DEFAULT_MOBILE_HEIGHT = 812;
export const DESKTOP_FOOTER_HEIGHT = 120;
export const MOBILE_FOOTER_HEIGHT = 80;
export const DEFAULT_VIEW_TYPE: FormatNames = 'fit';
