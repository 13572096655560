import isInIframe from '@anm/helpers/is/isInIframe';
import { useCallback, useEffect, useState } from 'react';

type HookProps<T> = {
  pageFromProps: T;
  onSetPage?: (page: T) => void;
};

const useDynamicPage = <T>(props?: HookProps<T>) => {
  const [page, setPage] = useState<T | undefined>(props?.pageFromProps);

  const onReceiveMessage = ({ data }: MessageEvent) => {
    data.landing && updatePage(data.landing);
  };

  useEffect(() => {
    window.parent.postMessage({ mounted: true }, '*');
  }, []);

  useEffect(() => {
    if (!isInIframe()) return;

    window.addEventListener('message', onReceiveMessage);

    return () => window.removeEventListener('message', onReceiveMessage);
  }, []);

  const updatePage = useCallback((page: T) => {
    setPage(page);
    props?.onSetPage?.(page);
  }, []);

  return [page, updatePage] as const;
};

export default useDynamicPage;
