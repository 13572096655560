import { LandingTheme } from '@anm/api/modules/hosting';
import { Themes } from '@anm/shared/types/branding';
import { css } from 'styled-components';

type TextBlock = 'headline' | 'body' | 'button';

export type FontFamily = { [key in TextBlock]: ReturnType<typeof css> };

type FontItem = {
  url: string[];
  family?: FontFamily;
};

const DEFAULT_FONT_NAME = 'Inter';

const defaultFontFamily = `font-family: 'Inter', sans-serif;`;
export const variableFontFamily = `font-family: 'Inter var', sans-serif;`;
const interFontUrl = 'https://assets.animatron.com/fonts/inter/inter.css';

export const defaultFonts = `
    @import url('https://fonts.googleapis.com/css?family=Source+Code+Pro&display=swap');
    @import url(${interFontUrl});
    html { ${defaultFontFamily} }
    @supports (font-variation-settings: normal) {
        html { ${variableFontFamily} }
    }
`;

export const landingFontsMap: { [key in LandingTheme]: FontItem } = {
  pure: {
    url: [`${interFontUrl}`],
    family: {
      headline: css`
        ${defaultFontFamily};
        font-weight: 700;
      `,
      body: css`
        ${defaultFontFamily}
      `,
      button: css`
        ${defaultFontFamily};
        font-weight: 700;
      `
    }
  },
  dawn: {
    url: ['https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap', `${interFontUrl}`],
    family: {
      headline: css`
        font-family: 'Archivo Black', sans-serif;
        font-weight: 400;
      `,
      body: css`
        ${defaultFontFamily}
      `,
      button: css`
        ${defaultFontFamily}
        font-weight: 700;
      `
    }
  },
  pale: {
    url: [`https://fonts.googleapis.com/css2?family=Archivo+Black&family=Poppins:wght@400;700&display=swap`],
    family: {
      headline: css`
        font-family: 'Poppins', sans-serif;
        font-weight: 700;
      `,
      body: css`
        font-family: 'Poppins', sans-serif;
      `,
      button: css`
        font-family: 'Poppins', sans-serif;
        font-weight: 700;
      `
    }
  },

  dusk: {
    url: ['https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&family=DM+Serif+Display&display=swap'],
    family: {
      headline: css`
        font-family: 'DM Serif Display', serif;
        font-weight: 400;
      `,
      body: css`
        font-family: 'DM Sans', sans-serif;
      `,
      button: css`
        font-family: 'DM Sans', sans-serif;
        font-weight: 700;
      `
    }
  },
  deep: {
    url: ['https://fonts.googleapis.com/css2?family=Bitter:ital,wght@0,700;1,400&display=swap'],
    family: {
      headline: css`
        font-family: 'Bitter', serif;
        font-weight: 700;
      `,
      body: css`
        font-family: 'Bitter', serif;
        font-style: italic;
      `,
      button: css`
        font-family: 'Bitter', serif;
        font-weight: 700;
      `
    }
  },
  lily: {
    url: ['https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap'],
    family: {
      headline: css`
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
      `,
      body: css`
        font-family: 'Roboto', sans-serif;
      `,
      button: css`
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
      `
    }
  },
  mist: {
    url: ['https://fonts.googleapis.com/css2?family=Merriweather+Sans&family=Merriweather:wght@700&display=swap'],
    family: {
      headline: css`
        font-family: 'Merriweather', serif;
        font-weight: 700;
      `,
      body: css`
        font-family: 'Merriweather Sans', sans-serif;
      `,
      button: css`
        font-family: 'Merriweather', serif;
        font-weight: 700;
      `
    }
  },
  sand: {
    url: ['https://fonts.googleapis.com/css2?family=Merriweather:ital@1&family=Playfair+Display:wght@700&display=swap'],
    family: {
      headline: css`
        font-family: 'Playfair Display', serif;
        font-weight: 700;
      `,
      body: css`
        font-family: 'Merriweather', serif;
      `,
      button: css`
        font-family: 'Playfair Display', serif;
        font-weight: 700;
      `
    }
  },
  dew: {
    url: ['https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@600;700&display=swap', `${interFontUrl}`],
    family: {
      headline: css`
        font-family: 'Source Serif Pro', serif;
        font-weight: 600;
      `,
      body: css`
        ${defaultFontFamily}
      `,
      button: css`
        font-family: 'Source Serif Pro', serif;
        font-weight: 700;
      `
    }
  },
  peony: {
    url: ['https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Pacifico&display=swap'],
    family: {
      headline: css`
        font-family: 'Pacifico', cursive;
      `,
      body: css`
        font-family: 'Open Sans', sans-serif;
      `,
      button: css`
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
      `
    }
  },
  night: {
    url: ['https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&family=Work+Sans:wght@400;700&display=swap'],
    family: {
      headline: css`
        font-family: 'Work Sans', sans-serif;
        font-weight: 700;
      `,
      body: css`
        font-family: 'Work Sans', sans-serif;
      `,
      button: css`
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
      `
    }
  },
  daisy: {
    url: ['https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,700;0,900;1,400&display=swap'],
    family: {
      headline: css`
        font-family: 'Raleway', sans-serif;
        font-weight: 900;
      `,
      body: css`
        font-family: 'Raleway', sans-serif;
        font-style: italic;
      `,
      button: css`
        font-family: 'Raleway', sans-serif;
        font-weight: 700;
      `
    }
  }
};

type Font = {
  name?: string;
  ttf?: string;
  woff?: string;
  woff2?: string;
  fontWeight?: number;
};

export const studioFontsMap: { [key in string]: Font } = {
  // sharp: {
  //   name: DEFAULT_FONT_NAME
  // },
  // frame: {
  //   name: DEFAULT_FONT_NAME
  // },
  // active: {
  //   name: 'Fugaz One',
  //   ttf: 'https://assets.animatron.io/2d1c905642197808d6e1a68b.ttf',
  //   woff: 'https://assets.animatron.io/2e1c90562373c63d1fa249ee.woff'
  // },
  modest: {
    name: 'Work Sans',
    ttf: 'https://assets.animatron.io/6909b75b3d0b26f29bc96bab.ttf',
    woff: 'https://assets.animatron.io/6a09b75b33d5c36b6765c66c.woff'
  },
  // rounded: {
  //   name: DEFAULT_FONT_NAME
  // },
  glass: {
    name: DEFAULT_FONT_NAME
  },
  dark_glass: {
    name: DEFAULT_FONT_NAME
  },
  elegant: {
    name: 'Merriweather',
    ttf: 'https://assets.animatron.io/3c8eab5aad278c1bcdace108.ttf',
    woff: 'https://assets.animatron.io/3d8eab5a378c15b8eea86cdc.woff'
  },
  // classic: {
  //   name: 'Work Sans',
  //   ttf: 'https://assets.animatron.io/441c90568aebad54dae8a55d.ttf',
  //   woff: 'https://assets.animatron.io/471c905625e2cb822f94fd16.woff'
  // },
  // oval: {
  //   name: DEFAULT_FONT_NAME
  // },
  bubble: {
    name: DEFAULT_FONT_NAME
  },
  // oval_frame: {
  //   name: DEFAULT_FONT_NAME
  // },
  // flow: {
  //   name: DEFAULT_FONT_NAME
  //   // no zilla slab font in the common fonts.json
  // },
  digital: {
    name: 'JetBrains Mono',
    ttf: 'https://assets.animatron.com/fonts/JetBrains_Mono/static/JetBrainsMono-Regular.ttf',
    woff2: 'https://assets.animatron.com/fonts/JetBrains_Mono/static/JetBrainsMono-Regular.woff2'
  },
  digital_medium: {
    name: 'JetBrains Mono',
    fontWeight: 500,
    ttf: 'https://assets.animatron.com/fonts/JetBrains_Mono/static/JetBrainsMono-Medium.ttf',
    woff2: 'https://assets.animatron.com/fonts/JetBrains_Mono/static/JetBrainsMono-Medium.woff2'
  },
  digital_extra_bold: {
    name: 'JetBrains Mono',
    fontWeight: 800,
    ttf: 'https://assets.animatron.com/fonts/JetBrains_Mono/static/JetBrainsMono-ExtraBold.ttf',
    woff2: 'https://assets.animatron.com/fonts/JetBrains_Mono/static/JetBrainsMono-ExtraBold.woff2'
  },
  sleek: {
    name: 'Roboto Italic',
    ttf: 'https://assets.animatron.io/fonts/2ff5d95f3bb3326e3cbaa6d6.ttf',
    woff: 'https://assets.animatron.io/fonts/30f5d95f515439c4f57c5371.woff'
  },
  // vivid: {
  //   name: 'Roboto Slab',
  //   ttf: 'https://assets.animatron.io/5f09b75b043f1a3cb639db25.ttf',
  //   woff: 'https://assets.animatron.io/6009b75be614cfbef2b11a54.woff'
  // },
  // shadow: {
  //   name: 'Archivo Black',
  //   ttf: 'https://assets.animatron.io/5309b75b1cf3fce7d0a10875.ttf',
  //   woff: 'https://assets.animatron.io/5709b75ba9e542fdeb0d765d.woff'
  // },
  brick: {
    name: 'Bebas Neue',
    ttf: 'https://assets.animatron.io/fonts/1bf5d95f4e485e31fec35bbb.ttf',
    woff: 'https://assets.animatron.io/fonts/1cf5d95f3f8fe6f7366b5f8f.woff'
  },
  // diamond: {
  //   name: DEFAULT_FONT_NAME
  // },
  pixel: {
    name: 'DM Mono',
    woff2: 'https://assets.animatron.io/fonts/aFTU7PB1QTsUX8KYthqQBK6PYK0.woff2'
  },
  pixel_medium: {
    name: 'DM Mono',
    fontWeight: 500,
    woff2: 'https://assets.animatron.io/fonts/aFTR7PB1QTsUX8KYvumzEYOtbYf-Vlg.woff2'
  }
  // strong: {
  //   name: 'Archivo Black',
  //   ttf: 'https://assets.animatron.io/5309b75b1cf3fce7d0a10875.ttf',
  //   woff: 'https://assets.animatron.io/5709b75ba9e542fdeb0d765d.woff'
  // }
};

export const injectStudioFonts = () =>
  Object.keys(studioFontsMap).map((theme: Themes) => {
    const currentTheme = studioFontsMap[theme];
    if (currentTheme.name === DEFAULT_FONT_NAME) return;

    const woff = currentTheme.woff;
    const woff2 = currentTheme.woff2;

    const isWoff2 = !!currentTheme.woff2;

    return `
      @font-face {
        font-family: ${currentTheme.name};
        src: url(${currentTheme.ttf}) format("ttf"),
            url(${isWoff2 ? woff2 : woff}) format("${isWoff2 ? 'woff2' : 'woff'}");
        font-weight: ${currentTheme.fontWeight || 'normal'}
      }
    `;
  });
