import { WAVE_API_PROD, WAVE_API_TEST, WAVE_EMBED_PROD, WAVE_EMBED_TEST, WAVE_PROD, WAVE_TEST, WAVE_WATCH_PROD, WAVE_WATCH_TEST } from '@anm/constants/domains';
import { MIXPANEL_ANALYTIC_API_HOST, MIXPANEL_ANALYTIC_WAVE_PROD, MIXPANEL_ANALYTIC_WAVE_TEST, MIXPANEL_CUSTOM_LIB_URL } from '@anm/constants/services';
var sentry = {
  dsn: 'https://451fb09843ee48f399538f9236445fa8@o1082595.ingest.sentry.io/4503898298580992',
  release: 'video-landings'
};
var configs = {
  dev: {
    sentry: sentry,
    urls: {
      waveUrl: WAVE_TEST,
      embedUrl: WAVE_EMBED_TEST,
      landingUrl: WAVE_WATCH_TEST
    },
    analytics: {
      mixpanel: {
        enable: false,
        id: MIXPANEL_ANALYTIC_WAVE_TEST,
        libUrl: MIXPANEL_CUSTOM_LIB_URL,
        apiHost: WAVE_API_TEST + MIXPANEL_ANALYTIC_API_HOST
      }
    }
  },
  test: {
    sentry: sentry,
    urls: {
      waveUrl: WAVE_TEST,
      embedUrl: WAVE_EMBED_TEST,
      landingUrl: WAVE_WATCH_TEST
    },
    analytics: {
      mixpanel: {
        enable: false,
        id: MIXPANEL_ANALYTIC_WAVE_TEST,
        libUrl: MIXPANEL_CUSTOM_LIB_URL,
        apiHost: WAVE_API_TEST + MIXPANEL_ANALYTIC_API_HOST
      }
    }
  },
  prod: {
    sentry: sentry,
    urls: {
      waveUrl: WAVE_PROD,
      embedUrl: WAVE_EMBED_PROD,
      landingUrl: WAVE_WATCH_PROD
    },
    analytics: {
      mixpanel: {
        enable: false,
        id: MIXPANEL_ANALYTIC_WAVE_PROD,
        apiHost: WAVE_API_PROD + MIXPANEL_ANALYTIC_API_HOST
      }
    }
  }
};
var currentEnv = process.env['TS_ENV'];
if (!currentEnv) throw new Error('Missed env: TS_ENV');
var config = configs[currentEnv];
export default config;